<template>
  <section>
    <p style="color: #404a7a">Proceso / P001-2022 / Ofertas</p>
    <p style="font-size: 24px; color: #404a7a">Recepción de ofertas</p>
    <v-col cols="12" sm="6" md="12">
      <v-col cols="12">
        <v-row class="align-baseline">
          <vc-date-picker
            v-model="fecha_inicio"
            mode="datetime"
            locale="es"
            :popover="config_calendar"
            :min-date="fecha_minima"
          >
            <template v-slot="{ inputEvents }">
              <v-text-field
                class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                label="Fecha y hora de inicio"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-on="inputEvents"
                outlined
                :disabled="true"
                :value="getFechaInicio"
                :error-messages="errorFechaInicio"
                @blur="$v.fecha_inicio.$touch()"
              />
            </template>
          </vc-date-picker>
          <vc-date-picker
            v-model="fecha_fin"
            mode="datetime"
            locale="es"
            :popover="config_calendar"
            :min-date="this.moment(fecha_inicio).format('YYYY-MM-DD HH:m')"
          >
            <template v-slot="{ inputEvents }">
              <v-text-field
                class="px-2 py-1 border roundend focus:outline-none focus:border-blue-300"
                label="Fecha y hora de fin"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-on="inputEvents"
                outlined
                :value="getFechaFin"
                :error-messages="errorFechaFin"
                @blur="$v.fecha_fin.$touch()"
              />
            </template>
          </vc-date-picker>
          <v-btn class="secondary mx-5" @click="actualizarFechaRecepcionOf()"
            >Guardar</v-btn
          >
        </v-row>
      </v-col>
    </v-col>
    <v-col cols="12">
      <v-btn color="secondary" @click="openModalAdd()"> Agregar </v-btn>
    </v-col>
    <v-col cols="12">
      <DataTable
        :headers="headers"
        :items="subprocesos"
        :show_actions="true"
        :actions="actions"
        v-models:select="filtros.per_page"
        v-models:page:="filtros.page"
        :total_registros="filtros.total_rows"
        @paginar="paginate"
        @edit="editarSubproceso"
        @delete="abrirModalEliminar"
      >
        <template v-slot:[`item.monto_sub_proceso_obs`]="{ item }">
          {{
            Intl.NumberFormat("en-US", {
              currency: "USD",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(item.monto_sub_proceso_obs)
          }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip label v-if="item.estado == 1" :color="getColor(item.estado)">
            Listo
          </v-chip>
          <v-chip label v-else :color="getColor(item.estado)">
            Pendiente
          </v-chip>
        </template>
      </DataTable>
    </v-col>
    <v-col cols="mt-5">
      <v-btn color="secondary" outlined @click="$router.back()">Volver</v-btn>
    </v-col>

    <!--Inicio del dialog-->
    <AppDialog
      v-model="value"
      title="Agrupador de solicitudes"
      :maxWidth="1000"
      persistent
    >
      <template #content>
        <v-row>
          <v-col cols="8">
            <v-text-field
              label="Nombre descriptivo del agrupador *"
              outlined
              v-model="form.nombre_agrupacion"
              :error-messages="errorNombreAgrupacion"
              @input="$v.form.nombre_agrupacion.$touch()"
              @blur="$v.form.nombre_agrupacion.$touch()"
              maxLength="45"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              outlined
              label="Especificación *"
              v-model="form.especificacion"
              :error-messages="errorEspecificacion"
              @input="$v.form.especificacion.$touch()"
              @blur="$v.form.especificacion.$touch()"
              maxLength="255"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              :items="listUnidadMedida"
              item-value="id"
              item-text="nombre"
              label="Unidad de medida *"
              v-model="form.id_unidad_medida"
              outlined
              @change="obtenerObsDetalleRecepcionOfertas"
            >
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="listUnidadOrg"
              item-value="id"
              item-text="nombre"
              label="Unidad organizacional"
              outlined
              v-model="form.id_unidad_organizacional"
              @change="obtenerObsDetalleRecepcionOfertas"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-subheader
          v-show="listaDetalle.length === 0"
          class="px-0 mx-0"
          :class="$v.selected.$dirty ? 'red--text' : ''"
          >No hay detalles disponibles</v-subheader
        >
        <div
          class="proceso-compra-element"
          v-for="data in listaDetalle"
          :key="data.id"
        >
          <v-list color="bgMinsal">
            <v-list-item class="ma-0">
              <v-list-item-content pa-0>
                <v-list-item-title>
                  {{ data.nombre_obs }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.unidad }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item class="ma-0">
              <v-list-item-content class="pa-0">
                <v-list-item-subtitle>
                  Especificación: {{ data.especificacion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col cols="10">
              <!-- simple Tabla de 4 columnas -->
              <table class="table">
                <thead>
                  <tr>
                    <th class="text-left">Cantidad</th>
                    <th class="text-left">Unidad de medida</th>
                    <th class="text-left">Precio unitario</th>
                    <th class="text-left">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-left">
                      {{ data.total_obs }}
                    </td>
                    <td class="text-left">
                      {{ data.unidad_medida }}
                    </td>
                    <td class="text-left">
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(data.precio_unitario)
                      }}
                    </td>
                    <td class="text-left">
                      {{
                        Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(data.monto_obs)
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
            <v-col cols="2">
              <v-checkbox
                v-model="selected"
                :value="data.id"
                :error-messages="errorListDetalle"
                @change="$v.selected.$touch()"
              ></v-checkbox>
            </v-col>
          </v-row>
        </div>
      </template>
      <template #actions>
        <div>
          <v-btn
            color="secondary"
            @click="agregarSubprocesoObs"
            class="mr-4 bgWhite--text"
          >
            Guardar
          </v-btn>
        </div>
      </template>
    </AppDialog>
    <!--Fin del dialog-->

    <ConfirmationDialogComponent
      :show="modalConfirmar"
      btnConfirmar="Aceptar"
      title="¿Desea eliminar el subproceso seleccionado?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="modalConfirmar = false"
      @confirm="eliminarSubproceso"
    />
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";
import AppDialog from "../../components/AppDialog.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import moment from "moment";
import { mapActions, mapState } from "vuex";

function dateValidation() {
  return (
    moment(this.fecha_inicio).format("YYYY-MM-DD HH:mm") <
    moment(this.fecha_fin).format("YYYY-MM-DD HH:mm")
  );
}

export default {
  name: "OfertaRecepcion",
  components: {
    DataTable,
    ConfirmationDialogComponent,
    AppDialog,
  },

  data: () => ({
    fecha_inicio: null,
    fecha_fin: null,
    fecha_minima: null,
    selected: [],
    obs: {},
    subprocesoId: null,
    subprocesoIdDelete: null, // se duplico por problemas de sincronizacion
    subprocesos: [],
    listUnidadMedida: [],
    listUnidadOrg: [],
    listaDetalle: [],
    value: false,
    modalConfirmar: false,
    filtros: {
      page: 1,
      per_page: 10,
      total_rows: 1,
    },
    form: {
      nombre_agrupacion: null,
      especificacion: null,
      id_unidad_medida: null,
      id_unidad_organizacional: null,
      detalles: [],
    },
    headers: [
      {
        text: "Especificación",
        align: "center",
        sortable: false,
        value: "nombre_agrupacion",
        width: "30%",
      },
      {
        text: "Cantidad",
        align: "center",
        value: "total_sub_proceso",
        width: "20%",
      },
      {
        text: "Monto ($)",
        align: "center",
        value: "monto_sub_proceso_obs",
        width: "20%",
      },
      { text: "Acciones", align: "center", value: "actions", width: "20%" },
    ],
    actions: [
      {
        eventName: "edit",
        tooltip: { text: "Editar", color: "blueGrayMinsal" },
        icon: { color: "secondary", name: "mdi-file-document-edit" },
      },
      {
        eventName: "delete",
        tooltip: { text: "Eliminar", color: "blueGrayMinsal" },
        icon: { color: "secondary", name: "mdi-delete" },
      },
    ],
    config_calendar: {
      visibility: "click",
    },
  }),

  validations: {
    fecha_inicio: {
      required: requiredIf(function () {
        return !this.value;
      }),
      dateValidation,
    },
    fecha_fin: {
      required: requiredIf(function () {
        return !this.value;
      }),
    },
    form: {
      nombre_agrupacion: {
        required: requiredIf(function () {
          return this.value;
        }),
      },
      especificacion: {
        required: requiredIf(function () {
          return this.value;
        }),
      },
    },
    selected: {
      required: requiredIf(function () {
        return this.value;
      }),
    },
  },

  computed: {
    ...mapState("procesoCompra", ["lista_fases", "fecha_inicio_anterior", "fecha_hora_fin"]),
    getFechaInicio() {
      return this.fecha_inicio
        ? moment(this.fecha_inicio).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    getFechaFin() {
      return this.fecha_fin
        ? moment(this.fecha_fin).format("DD/MM/YYYY hh:mm A")
        : "";
    },
    errorFechaInicio() {
      const errors = [];
      if (!this.$v.fecha_inicio.$dirty) return errors;
      !this.$v.fecha_inicio.required &&
        errors.push("Fecha de inicio es requerida");
      !this.$v.fecha_inicio.dateValidation &&
        errors.push("La fecha de inicio debe ser menor a la fecha de fin");
      return errors;
    },
    errorFechaFin() {
      const errors = [];
      if (!this.$v.fecha_fin.$dirty) return errors;
      !this.$v.fecha_fin.required && errors.push("Fecha de fin es requerida");
      return errors;
    },

    errorNombreAgrupacion() {
      const errors = [];
      if (!this.$v.form.nombre_agrupacion.$dirty) return errors;
      !this.$v.form.nombre_agrupacion.required &&
        errors.push("Nombre de agrupación es requerido");
      return errors;
    },

    errorEspecificacion() {
      const errors = [];
      if (!this.$v.form.especificacion.$dirty) return errors;
      !this.$v.form.especificacion.required &&
        errors.push("La especificación es requerida");
      return errors;
    },

    errorListDetalle() {
      const errors = [];
      if (!this.$v.selected.$dirty) return errors;
      this.selected.length < 1 && errors.push("Seleccione al menos un ítem");
      return errors;
    },
  },
  watch: {
    value() {
      if (!this.value) {
        this.reset();
      } else {
        if (!this.isEdit) this.obtenerObsDetalleRecepcionOfertas();
      }
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "putFechasRecepcionOfertas",
      "getFasesProcesoCompra",
      "getSubprocesoObsRecepcionOfertas",
      "getInfoSubprocesoObs",
      "getObsDetalleRecepcionOfertas",
      "deleteSubprocesoRecepcionOferta",
      "putSubprocesoRecepcionOferta",
      "postSubprocesoObsRecepcionOfertas",
    ]),

    getColor(estado) {
      if (estado == 1) return "#c1ecd9";
      else if (estado == 2) return "#e1e5f0";
    },
    openModalAdd() {
      this.$v.$reset();
      this.value = true;
    },
    async obtenerSubprocesoObs() {
      const data = {
        procesoCompraId: this.$route.params.id_proceso_compra,
        filtros: { ...this.filtros },
      };
      const respSubProceso = await this.getSubprocesoObsRecepcionOfertas(data);
      this.subprocesos = respSubProceso?.data;
      this.setPaginacion(respSubProceso?.headers);
    },

    setPaginacion(payload) {
      this.filtros.page = Number(payload.page);
      this.filtros.per_page = Number(payload.per_page);
      this.filtros.total_rows = Number(payload.total_rows);
    },

    async paginate(params) {
      const { cantidad_por_pagina, pagina } = params;
      (this.filtros.page = pagina),
        (this.filtros.per_page = cantidad_por_pagina);
      await this.obtenerSubprocesoObs();
    },

    async editarSubproceso(item) {
      this.value = true;
      this.isEdit = true;
      this.subprocesoId = item.id;
      const response = await this.getInfoSubprocesoObs(item.id);
      const {
        especificacion,
        id_unidad_medida,
        id_unidad_organizacional,
        nombre_agrupacion,
      } = response?.data?.sub_proceso;
      this.form.nombre_agrupacion = nombre_agrupacion;
      this.form.especificacion = especificacion;
      (this.form.id_unidad_medida = id_unidad_medida),
        (this.form.id_unidad_organizacional = id_unidad_organizacional);
      await this.obtenerObsDetalleRecepcionOfertas(false);
    },

    async obtenerObsDetalleRecepcionOfertas(change = true) {
      let data = {
        id_proceso_compra: this.$route.params.id_proceso_compra,
        body: {
          id_presentacion: this.form.id_unidad_medida,
          id_unidad: this.form.id_unidad_organizacional,
        },
      };

      if (this.subprocesoId !== null || this.isEdit)
        data.body.id_sub_proceso_obs = this.subprocesoId;

      const respDetalle = await this.getObsDetalleRecepcionOfertas(data);
      this.listaDetalle = respDetalle?.data;

      if (this.subprocesoId !== null || this.isEdit)
        respDetalle?.data.forEach((detalle) => this.selected.push(detalle.id));

      if (change) {
        this.selected = [];
        this.form.detalles = [];
      }
    },

    abrirModalEliminar(item) {
      this.subprocesoIdDelete = item.id;
      this.modalConfirmar = true;
    },

    async eliminarSubproceso() {
      this.modalConfirmar = false;
      const response = await this.deleteSubprocesoRecepcionOferta(
        this.subprocesoIdDelete
      );
      if (response.status == 200) {
        await this.obtenerSubprocesoObs();
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data?.message,
        });
      }
    },

    async agregarSubprocesoObs() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.form.detalles = this.selected;
      let response;
      let data = {
        body: this.form,
      };

      if (this.isEdit) {
        data.id_sub_proceso_obs = this.subprocesoId;
        response = await this.putSubprocesoRecepcionOferta(data);
      } else {
        data.id_proceso_compra = this.$route.params.id_proceso_compra;
        response = await this.postSubprocesoObsRecepcionOfertas(data);
      }

      if (response.status == 201 || response.status == 200) {
        await this.obtenerSubprocesoObs();
        this.reset();
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data.message,
        });
      }
    },

    async obtenerListaMedida() {
      const unidadMedida =
        await this.services.Presentations.getListPresentations();
      this.listUnidadMedida = unidadMedida?.data;
    },

    async obtenerUnidadesOrg() {
      const unidadOrg = await this.services.UnidadesServices.getAllUnidades();
      this.listUnidadOrg = unidadOrg?.data;
    },

    reset() {
      this.value = false;
      this.selected = [];
      this.listaDetalle = [];
      this.form = {
        nombre_agrupacion: null,
        especificacion: null,
        id_unidad_medida: null,
        id_unidad_organizacional: null,
        detalles: [],
      };
      this.$v.$reset();
      if (this.isEdit) {
        this.subprocesoId = null;
        this.isEdit = false;
      }
    },

    async actualizarFechaRecepcionOf() {
      this.$v.$touch();

      if (this.$v.$invalid) return;
      const data = {
        procesoCompraId: this.$route.params.id_proceso_compra,
        body: {
          fecha_hora_inicio: moment(this.fecha_inicio).format(
            "DD-MM-YYYY HH:mm:ss"
          ),
          fecha_hora_fin: moment(this.fecha_fin).format("DD-MM-YYYY HH:mm:ss"),
        },
      };
      const response = await this.putFechasRecepcionOfertas(data);
      if (response.status == 200 || response.status == 201) {
        this.temporalAlert({
          show: true,
          type: "success",
          message: response?.data?.message,
        });
      }
    },

    obtenerFechaMinima() {
      const filter = this.lista_fases.filter(
        (item) => item.id === this.$route.params.etapa
      );
      const etapa = this.lista_fases.indexOf(filter[0]);
      this.fecha_minima = this.lista_fases[etapa - 1]?.fecha_hora_fin;
    },
  },
  async created() {
    if (
      this.fecha_inicio_anterior === undefined ||
      this.fecha_inicio_anterior === null
    ) {
      this.$router.back();
    } else {
      this.fecha_inicio = moment(this.fecha_inicio_anterior).format(
        "YYYY-MM-DD HH:mm"
      );
    }
    if(this.fecha_hora_fin !== null || this.fecha_hora_fin !== undefined){
      this.fecha_fin = moment(this.fecha_hora_fin).format("YYYY-MM-DD HH:mm")
    }
    this.obtenerFechaMinima();

    await this.obtenerSubprocesoObs();
    await this.obtenerListaMedida();
    await this.obtenerUnidadesOrg();
  },
};
</script>
<style lang="scss" scope>
.proceso-compra-element {
  width: 100%;
  margin: 8px 0px;
  padding: 0px 8px 16px 8px;
  border-radius: 4px;
  background-color: var(--v-bgMinsal-base);
}

.table {
  margin: 0px 16px;
  width: 80%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0px;
  font-size: 14px;
  th {
    // Sin color de fondo color del texto negro bold
    background-color: var(--v-bgMinsal-base) !important;
    color: var(--v-textMinsal-base) !important;
    font-weight: bold !important;
  }
  tr {
    // Texto centrado en la celda
    text-align: center !important;
  }
}
</style>
